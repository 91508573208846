<template>
  <div>
    <appbar @drawer="open" />
    <appdrawer :drawer="value"></appdrawer>
    <v-sheet
      style="height: 100vh; background: rgba(232, 232, 232, 0.06)"
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
      <v-main style="height: 100vh;"><router-view /></v-main>
    </v-sheet>
  </div>
</template>

<script>
import Drawer from "./Drawer";
import Header from "./Header";
export default {
  name: "Master",
  components: {
    appbar: Header,
    appdrawer: Drawer
  },
  data() {
    return {
      value: true
    };
  },
  methods: {
    open(value) {
      this.value = value;
    }
  }
};
</script>
