<template>
  <v-navigation-drawer
    v-model="close"
    style="background-color: #ffffff"
    app
    light
    :mini-variant.sync="mini"
  >
    <v-list-item style="padding: 4px 10px;">
      <v-list-item-title
        class="subtitle-1 blue-grey--text ml-3 font-weight-bold"
        >Pega</v-list-item-title
      >

      <v-btn icon @click.stop="mini = !mini">
        <v-icon color="#04a9f3">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>
    <div class="side_bar">
      <v-list dense>
        <v-list-item
          v-for="item in linkItem"
          :key="item.title"
          router
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                class="font"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="subItem.link"
            router
          >
            <!-- <v-list-item-icon>
              <v-icon style="font-size: .98em;">{{ subItem.icon }}</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title
                class="grey--text text--darken-2 font"
                style="text-transform: capitalize; font-size: 12px;"
                v-text="subItem.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    drawer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mini: false,
      close: true,
      linkItem: [
        {
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          link: { name: "master.dashboard" }
        },
        {
          icon: "group",
          title: "Customers",
          link: { name: "master.customers" }
        }
      ],
      items: [
        {
          icon: "mdi-border-color",
          title: "Fuel Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Pumps",
              link: { name: "master.pumps" }
            },
            {
              icon: "mdi-minus-box",
              title: "Products",
              link: { name: "master.products" }
            }
            // {
            //   icon: "mdi-minus-box",
            //   title: "Lubes",
            //   link: { name: "master.lubes" },
            // },
          ]
        },
        {
          icon: "mdi-basket-minus",
          title: "Lubes Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "List",
              link: { name: "master.lubes" }
            },
            {
              icon: "mdi-minus-box",
              title: "Pending Requests",
              link: { name: "master.lubes.pending.requests" }
            },
            {
              icon: "mdi-minus-box",
              title: "Approved Requests",
              link: { name: "master.lubes.approved.requests" }
            }
          ]
        },
        {
          icon: "mdi-account-group",
          title: "Pump Attendants",
          active: true,
          items: [
            {
              icon: "mdi-minus-box",
              title: "All Attendants",
              link: { name: "master.dispensers" }
            },
            {
              icon: "mdi-minus-box",
              title: "Daily Activities",
              link: { name: "master.dispensers.activities" }
            }
          ]
        },

        {
          icon: "local_shipping",
          title: "Deliveries Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Fuels",
              link: { name: "master.deliveries.products" }
            },
            {
              icon: "mdi-minus-box",
              title: "Lubes",
              link: { name: "master.deliveries.lubes" }
            }
          ]
        },
        {
          icon: "shopping_basket",
          title: "Stock Mgmt",
          items: [
            {
              icon: "mdi-minus-box",
              title: "All Stock Items",
              link: { name: "master.stock.list" }
            }
          ]
        },
        {
          icon: "trending_up",
          title: "Sales Records",
          items: [
            {
              icon: "list",
              title: "Sales List",
              link: { name: "master.sales.list" }
            },
            {
              icon: "add",
              title: "Record Shop Sales",
              link: { name: "master.sales.shops.today" }
            },
            {
              icon: "add",
              title: "Record Lube Sales",
              link: { name: "master.sales.lubes.today" }
            },
            {
              icon: "add",
              title: "Record Total Fuel Sales",
              link: { name: "master.sales.today" }
            }
          ]
        },
        {
          icon: "mdi-account-multiple-check",
          title: "Settings",
          items: [
            {
              icon: "mdi-minus-box",
              title: "System",
              link: { name: "master.settings.system" }
            },
            {
              icon: "mdi-minus-box",
              title: "Users",
              link: { name: "master.users" }
            },
            {
              icon: "mdi-minus-box",
              title: "ACL",
              link: { name: "master.roles" }
            }
            // {
            //   icon: "mdi-minus-box",
            //   title: "Display",
            //   link: { name: "master.display" }
            // }
          ]
        },
        {
          icon: "bar_chart",
          title: "Reports",
          items: [
            {
              icon: "mdi-minus-box",
              title: "Sales & Orders",
              link: { name: "master.reports.overview" }
            },
            {
              icon: "mdi-minus-box",
              title: "Stock Levels",
              link: { name: "master.stock.levels" }
            },
            {
              icon: "mdi-download",
              title: "Exports & Downloads",
              link: {
                name: "master.reports.downloads"
              }
            }
            // {
            //   icon: "mdi-minus-box",
            //   title: "Reports",
            //   link: { name: "master.reports" }
            // },
            // {
            //   icon: "mdi-minus-box",
            //   title: "Reports",
            //   link: { name: "master.reports" }
            // }
          ]
        }
      ]
    };
  },
  watch: {
    drawer(value) {
      this.close = value;
    }
  },

  created() {
    this.close = this.drawer;
  }
};
</script>

<style scoped></style>
